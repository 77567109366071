import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  warningContainer: FelaCSS;
}

const styles = (): IStyles => ({
  warningContainer: {
    padding: 4,
    border: `1px solid ${colors.peachRose120}`,
    borderRadius: '16px',
    backgroundColor: colors.white,
    fontDefault: 16,
    textAlign: 'start',
  },
});

export default styles;
