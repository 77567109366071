import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  inputContainer: FelaCSS;
  formContainer: FelaCSS;
  resetPasswordContainer: FelaCSS;
  resetPasswordPopup: FelaCSS;
  resetPassword: FelaCSS;
  submitButton: FelaCSS;
}

interface IStylesProps {
  isDirty: boolean;
  isValid: boolean;
}

const styles = ({ isDirty, isValid }: IStylesProps): IStyles => ({
  inputContainer: {
    paddingHorizontal: 3,
    paddingVertical: 3,
    gap: '10px',
  },
  formContainer: {
    marginTop: 24,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  submitButton: {
    backgroundColor: !isDirty || !isValid ? colors.space40 : colors.indigoCrush,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
    paddingRight: 8,
    color: colors.white,
    border: 'none',
    borderRadius: '54px',
    fontDefault: 16,
  },
  resetPasswordContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  resetPassword: {
    fontDefault: 15,
    color: colors.indigoCrush,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  resetPasswordPopup: {
    position: 'absolute',
  },
});

export default styles;
