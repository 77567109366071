import React, { useCallback, useMemo } from 'react';
import gazetteer, { Market } from '@bridebook/toolbox/src/gazetteer';
import {
  CountrySelector as CountrySelectorUI,
  ICountryOption,
  ICountrySelectorProps,
} from '@bridebook/ui';
import { CountrySelectModalContent } from 'app-shared/components/contry-selector/country-select-modal-content';
import Modal from 'components/bbcommon/modal';
import { getIsMobile } from 'lib/app/selectors';
import { useSelector } from 'lib/utils';

interface IProps extends Omit<ICountrySelectorProps, 'countries' | 'options'> {
  /**
   * Mobile modal title. Used only for mobile view. If not defined, it will use the inputLabel
   * to to maintain backward compatibility.
   */
  modalHeader?: string;

  /**
   * Placeholder for the filter input. Used only for mobile view.
   */
  filterPlaceholder?: string;

  /**
   * Filter functions allowing to filter the countries list.
   */
  countriesFilter?(value: Market): boolean;

  /**
   * Additional options mapper to map the market to the dropdown options.
   */
  optionsMapper?(market: Market): ICountryOption;
}

const CountrySelector = ({
  placeholder,
  onSelect,
  fullWidth,
  value,
  label,
  selected,
  showFilter,
  modalHeader,
  filterPlaceholder,
  optionsMapper,
  countriesFilter,
  ...rest
}: IProps) => {
  const isMobile = useSelector(getIsMobile);

  // Default options mapper
  const defaultOptionsMapper = useCallback(
    (market: Market): ICountryOption => ({
      value: market.country,
      label: market.getCountryName(),
      flagImg: market.getCountryFlagImageURL(),
      market,
    }),
    [],
  );

  // Map markets to dropdown options
  const options = useMemo(
    () =>
      gazetteer
        .getMarkets()
        .filter((market) => (countriesFilter ? countriesFilter(market) : true))
        .map((market) => optionsMapper?.(market) || defaultOptionsMapper(market))
        .sort((a, b) => a.market.getCountryName().localeCompare(b.market.getCountryName())),
    [countriesFilter, defaultOptionsMapper, optionsMapper],
  ) as ICountryOption[];

  return (
    <CountrySelectorUI
      showFilter={showFilter}
      label={label}
      options={options}
      placeholder={placeholder}
      onSelect={onSelect}
      isMobile={isMobile}
      value={value}
      fullWidth={fullWidth}
      selected={selected}
      {...rest}>
      {(onClose, onClick, showModal) =>
        showModal && isMobile ? (
          <Modal id="country-select-modal" show fullContentHeight onClose={onClose}>
            <CountrySelectModalContent
              onClose={onClose}
              options={options}
              onClick={onClick}
              modalHeader={modalHeader || label}
              showFilter={showFilter}
              filterPlaceholder={filterPlaceholder}
              selected={selected}
            />
          </Modal>
        ) : null
      }
    </CountrySelectorUI>
  );
};

export default CountrySelector;
