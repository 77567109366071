import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFela } from 'react-fela';
import { Box, ButtonV2, ImageX } from '@bridebook/ui';
import { Link } from 'app-shared/components/link/link';
import styles from 'components/auth/signup-mobile-welcome-component/signup-mobile-welcome-component.style';
import { imgixBaseURL, useSelector } from 'lib/utils';
import { getIsMobile, getIsTablet } from '../../../lib/app/selectors';
import { UrlHelper } from '../../../lib/url-helper';
import { StepHeader } from '../../onboarding-new/components/step-header';

interface IProps {
  redirect: (isSignUp: boolean, url: string) => void;
}

const SignupMobileWelcomeComponent = ({ redirect }: IProps) => {
  const { css } = useFela();
  const { t } = useTranslation('signup');

  const isMobile = useSelector(getIsMobile);
  const isTablet = useSelector(getIsTablet);

  return (
    <Box style={styles.signupContent} data-name="signupMobileWelcome">
      <Box style={styles.topSection}>
        <ImageX w={400} h={300} src={`${imgixBaseURL}/assets/signup-imgs/signup-background.png`} />
        <Box style={styles.sparkles}>
          <ImageX w={400} h={300} src={`${imgixBaseURL}/assets/signup-imgs/sparkles-mobile.png`} />
        </Box>
      </Box>
      <Box style={styles.contentContainer}>
        <StepHeader title={t('signup.welcomeTitle')} subtitle={t('signup.welcomeSubtitle')} />
        {isMobile && <Box style={styles.mobileSpacer} />}
        {isTablet && <Box style={styles.tabletTopSpacer} />}
        <ButtonV2
          onClick={() => redirect(true, UrlHelper.signup)}
          size="large"
          dataTest="auth-button-confirm"
          variant="primary"
          styleWrapper={isMobile ? styles.mobileWelcomeButtonWrapper : undefined}
          styleButton={styles.mobileWelcomeButton}>
          {t('getStarted')}
        </ButtonV2>
        <ButtonV2
          onClick={() => redirect(false, UrlHelper.login)}
          size="large"
          dataTest="auth-button-confirm"
          variant="secondary"
          styleButton={styles.mobileWelcomeButton}>
          {t('logIn')}
        </ButtonV2>
        {isTablet && <Box style={styles.tabletBottomSpacer} />}
        <Box style={styles.termsAndConditions}>
          {t('signup.termsAndConditionsStartText') + ' '}
          <Link className={css(styles.termsAndConditionsLink)} href="/terms">
            {t('signup.termsAndConditionsLinkText')}
          </Link>
          {' ' + t('signup.termsAndConditionsMidText') + ' '}
          <Link className={css(styles.termsAndConditionsLink)} href="/privacy-policy">
            {t('signup.termsAndConditionsPrivacyPolicyLinkText')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupMobileWelcomeComponent;
