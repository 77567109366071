import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Box } from '@bridebook/ui';
import { IconApple, IconFacebook, IconGoogleColor } from '@bridebook/ui/src/icons';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useAuthContext } from 'components/auth/auth-context';
import SocialAuthButton from 'components/auth/social-auth/social-auth-button';
import { LoginOrSignupResult } from 'lib/api/authenticate/types';
import { disableAuthForm, toggleSelectedCountryError } from 'lib/auth/actions';
import { isCountrySelected } from 'lib/auth/selectors';
import { AuthProviders } from 'lib/auth/types';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getIsIOS, getIsMacOS } from 'lib/mobile-app/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import componentStyles from './social-auth.style';

interface IProps {
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
  isSignUp?: boolean;
}

const SocialAuth = ({ isSignUp, setCollaborationAnimationVisible }: IProps) => {
  const { t } = useTranslation('signup');
  const {
    loginOrSignupWithGoogle,
    loginOrSignupWithApple,
    loginOrSignupWithFacebook,
    redirectAfterLoginOrSignup,
    prepareRedirectAfterLoginOrSignup,
  } = useAuthContext();
  const dispatch = useDispatch();
  const authForm = useSelector((state) => state.auth.form);
  const market = useMarket();
  const emailExistsWithDifferentProvider = useSelector(
    (state) => state.auth.emailExistsWithDifferentProvider,
  );
  const countrySelected = useSelector(isCountrySelected);
  const isiOS = useSelector(getIsIOS);
  const isMacOS = useSelector(getIsMacOS);

  const isAppleVisible = isiOS || isMacOS;

  const toggleError = isSignUp && !countrySelected;

  const handleSocialLogin = useCallback(
    async (provider: Exclude<AuthProviders, AuthProviders.PASSWORD>) => {
      if (toggleError) {
        dispatch(toggleSelectedCountryError(true, provider));
        dispatch(toggleSnackbar('alert', t('pleaseSelectCountry')));
      } else {
        dispatch(disableAuthForm);
        let result: LoginOrSignupResult | undefined;
        const loginOrSignupParams = { countryCode: market.country, locale: market.locale };

        switch (provider) {
          case AuthProviders.APPLE:
            result = await loginOrSignupWithApple(loginOrSignupParams);
            break;
          case AuthProviders.GOOGLE:
            result = await loginOrSignupWithGoogle(loginOrSignupParams);
            break;
          case AuthProviders.FACEBOOK:
            result = await loginOrSignupWithFacebook(loginOrSignupParams);
            break;
        }

        if (result && result.status === 'success' && !result.isCollaborator) {
          await redirectAfterLoginOrSignup(result);
        }
        if (result && result.status === 'success' && result.isCollaborator) {
          prepareRedirectAfterLoginOrSignup(result);
          setCollaborationAnimationVisible(true);
        }
      }
    },
    [
      dispatch,
      loginOrSignupWithApple,
      loginOrSignupWithFacebook,
      loginOrSignupWithGoogle,
      redirectAfterLoginOrSignup,
      setCollaborationAnimationVisible,
      t,
      toggleError,
    ],
  );

  const styles = componentStyles();
  return (
    <Box>
      <Box style={styles.socialButtonContainer}>
        {isAppleVisible && (
          <SocialAuthButton
            disabled={authForm.disabled}
            variant={'secondary'}
            size={'large'}
            text={isSignUp ? t('signupApple') : t('loginApple')}
            onClick={() => {
              handleSocialLogin(AuthProviders.APPLE);
            }}
            icon={<IconApple width={18} color="black" />}
          />
        )}
        {emailExistsWithDifferentProvider !== AuthProviders.GOOGLE && (
          <SocialAuthButton
            disabled={authForm.disabled}
            variant={'secondary'}
            size={'large'}
            text={isSignUp ? t('signupGoogle') : t('loginGoogle')}
            onClick={() => {
              handleSocialLogin(AuthProviders.GOOGLE);
            }}
            icon={<IconGoogleColor width={19} />}
          />
        )}
        {emailExistsWithDifferentProvider !== AuthProviders.FACEBOOK && (
          <SocialAuthButton
            disabled={authForm.disabled}
            variant={'secondary'}
            size={'large'}
            text={isSignUp ? t('signupFacebook') : t('loginFacebook')}
            onClick={() => {
              handleSocialLogin(AuthProviders.FACEBOOK);
            }}
            icon={<IconFacebook color="facebookBlue" width={10} />}
          />
        )}
      </Box>
    </Box>
  );
};

export default SocialAuth;
