import { getPathname } from 'app-basic/lib/app/selectors';
import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { runInUnauthenticatedFirebaseContext } from '@bridebook/toolbox/src/api/firebase-client';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import Head from 'components/app/head';
import SignupComponent from 'components/auth/signup-component/signup-component';
import { toggleAuthFormStateToSignup, viewedSignUpPageAnalytics } from 'lib/auth/actions';
import { getIsSignup } from 'lib/auth/selectors';
import { useSaveCollaboratorInvite } from 'lib/auth/utils/use-save-collaborator-invite';
import withCollaboratorInvite from 'lib/auth/with-collaborator-invite';
import { changeLanguage } from 'lib/change-language/utils/change-language';
import { getServerSidePropsWrapper } from 'lib/get-server-side-props-wrapper';
import { getCookieLocale } from 'lib/i18n/utils/get-cookie-locale';
import { withTranslations } from 'lib/i18n/with-translations';
import { LayoutFullProps, withFullLayout } from 'lib/layout-full';
import { ICollaboratorInviteType } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

interface IProps {
  serverCollaboratorInvite?: ICollaboratorInviteType;
}

const SignupPage = withTranslations(
  ({ serverCollaboratorInvite }: IProps) => {
    const { t } = useTranslation('signup');
    const isSignUp = useSelector(getIsSignup);
    useSaveCollaboratorInvite(serverCollaboratorInvite);
    const title = isSignUp ? t('pageTitle.signUp') : t('pageTitle.logIn');
    const dispatch = useDispatch();
    const { country } = useMarket();
    const path = useSelector(getPathname);
    const analyticsSent = useRef(false);
    const market = useMarket();

    useEffect(() => {
      // On mobile app we are getting an extra redirection to signup?welcome=show, so we need to check if we already sent the analytics
      if (!analyticsSent.current && path === UrlHelper.signup) {
        dispatch(viewedSignUpPageAnalytics({ selectedWeddingCountry: country }));
        analyticsSent.current = true;
      }
    }, [country, dispatch, path]);

    useLayoutEffect(() => {
      //Display default market language instead of the value from cookie on signup page
      const cookieLocale = getCookieLocale();
      if (market.locale !== cookieLocale) {
        changeLanguage(market.locale);
      }
    }, [market]);

    return (
      <>
        <Head title={title} isIndexable={false} />
        <SignupComponent />
      </>
    );
  },
  ['signup', 'auth', 'onboardingNew'],
);

export const getServerSideProps = getServerSidePropsWrapper(
  async ({ store, context }) => {
    const { req, query } = context;

    store.dispatch(toggleAuthFormStateToSignup(context.resolvedUrl !== UrlHelper.login));

    // Collaborator signup/signin flow
    if (query.id && query.nonce) {
      let serverCollaboratorInvite;
      await runInUnauthenticatedFirebaseContext(async () => {
        serverCollaboratorInvite = await withCollaboratorInvite(req, query);
      });

      return { props: { serverCollaboratorInvite, isLoggedIn: true } };
    }
  },
  { addAlternateLinks: true },
);
const ConnectedSignup: NextPage<IProps, LayoutFullProps> = SignupPage;

ConnectedSignup.Layout = function Layout(page, pageProps, layoutProps) {
  return withFullLayout(page, pageProps, layoutProps, {
    hideFooterTop: true,
    hideHeader: true,
    isSignupPage: true,
    disableSafeAreaInsetTop: true,
    hideFooter: layoutProps.isCordova,
  });
};

export default ConnectedSignup;
