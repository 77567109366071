import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { IDropdownOption } from '../../../types';
import { Dropdown, IDropdownProps } from '../dropdown/dropdown';

export type ICountryOption = IDropdownOption<{ flagImg?: string; market: Market }, string, string>;
export interface ICountrySelectorProps
  extends Omit<IDropdownProps<ICountryOption>, 'options' | 'onSelect'> {
  options: ICountryOption[];

  /**
   * Callback function to be called when an option is clicked.
   */
  onSelect: (option: ICountryOption) => void;

  /**
   * By default, the country selector will display the label of the selected option. If you want to
   * override this behavior, you can pass a value to be displayed instead. Passing null will cause only
   * flag to be displayed. Mind, that whatever you pass - the flag will always be displayed.
   */
  value?: string | null;

  /**
   * Additional modal content to be displayed when the input field is clicked (for mobile usage for example).
   */
  children?: (
    onClose: () => void,
    onSelect: ICountrySelectorProps['onSelect'],
    showModal: boolean,
  ) => ReactNode;
}

const CountrySelector = ({
  label,
  placeholder,
  onSelect,
  fullWidth,
  options,
  value,
  selected,
  showFilter,
  isMobile,
  children,
  error,
  required,
  renderOption,
  ...rest
}: ICountrySelectorProps) => {
  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), []);
  const onInputClick = useCallback(() => setShowModal(true), []);

  const sanitizedOptions = useMemo(() => options.filter(Boolean), [options]);

  return (
    <>
      <Dropdown
        showFilter={showFilter}
        label={label}
        options={sanitizedOptions}
        placeholder={placeholder}
        onSelect={onSelect}
        isMobile={isMobile}
        onInputClick={onInputClick}
        renderOption={(option) => (
          <>
            {option.flagImg && <img src={option.flagImg} width={25} loading="lazy" />}
            {renderOption?.(option) || option.label}
          </>
        )}
        value={(option) => (
          <>
            {option.flagImg && <img src={option.flagImg} width={25} />}
            {typeof value === 'undefined' ? option.label : value}
          </>
        )}
        fullWidth={fullWidth}
        selected={selected}
        error={error}
        required={required}
        {...rest}
      />
      {children?.(onClose, onSelect, showModal)}
    </>
  );
};

export default CountrySelector;
