import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, memo, useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { capitalizeFirstLetterAndLowercaseRest } from '@bridebook/toolbox/src/utils/strings';
import { Box, ButtonV2, InputPassword, InputV2 } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useAuthContext } from 'components/auth/auth-context';
import ConfirmEmailPopup from 'components/auth/signup-login-form/confirm-email-popup';
import ResetPasswordPopup from 'components/auth/signup-login-form/reset-password-popup';
import componentStyles from 'components/auth/signup-login-form/signup-login-form.style';
import { getIsDesktop, selectIsCordova } from 'lib/app/selectors';
import { disableAuthForm, signInError, toggleSelectedCountryError } from 'lib/auth/actions';
import { getCustomAuthMessages } from 'lib/auth/custom-auth-messages';
import { getFirebaseMessages } from 'lib/auth/firebase-messages';
import { isCountrySelected } from 'lib/auth/selectors';
import {
  AuthBridebookError,
  AuthProviders,
  CustomAuthErrorCodes,
  FirebaseErrorCodes,
  ICredentialsFields,
} from 'lib/auth/types';
import { BridebookError, IIntlMessageDescriptor } from 'lib/types';
import { createReverseMappingForEnum, useDispatch, useSelector } from 'lib/utils';

interface IProps {
  setAuthData: Dispatch<SetStateAction<ICredentialsFields>>;
  authData: ICredentialsFields;
  isSignUp?: boolean;
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
}

const SignupLoginForm = ({
  setAuthData,
  authData,
  isSignUp,
  setCollaborationAnimationVisible,
}: IProps) => {
  const { t } = useTranslation('signup');
  const dispatch = useDispatch();
  const { register, login, redirectAfterLoginOrSignup, prepareRedirectAfterLoginOrSignup } =
    useAuthContext();
  const isDesktop = useSelector(getIsDesktop);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const emailExists = useSelector((state) => state.auth.emailExists);
  const authForm = useSelector((state) => state.auth.form);
  const formError = authForm.error as AuthBridebookError;
  const reverseAuthProviders = createReverseMappingForEnum(AuthProviders);
  const countrySelected = useSelector(isCountrySelected);
  const isCordova = useSelector(selectIsCordova);
  const market = useMarket();
  const countryCode = market.country;
  const locale = market.locale;

  const onSubmit: SubmitHandler<ICredentialsFields> = (data) => setAuthData(data);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    getValues,
  } = useForm<ICredentialsFields>();

  const getError = useCallback(
    (fieldErrorMessage?: string) => {
      if (fieldErrorMessage) return fieldErrorMessage;
      if (emailExists) return t('emailExists');
      if (formError?.prop === 'email' && formError.code) {
        const oneOfFirebaseErrors: IIntlMessageDescriptor =
          getFirebaseMessages()[formError.code as FirebaseErrorCodes];
        const oneOfCustomErrors: IIntlMessageDescriptor =
          getCustomAuthMessages()[formError.code as CustomAuthErrorCodes];
        if (oneOfFirebaseErrors) {
          return oneOfFirebaseErrors.defaultMessage;
        }
        if (oneOfCustomErrors) {
          return oneOfCustomErrors.defaultMessage;
        }
      }
      if (formError?.prop === 'email') return t('wrongEmail');
      if (
        formError?.code === 'auth/account-exists-with-different-credential' &&
        formError?.provider
      )
        return t('accountUsedWithDifferentProvider', {
          provider: capitalizeFirstLetterAndLowercaseRest(reverseAuthProviders[formError.provider]),
        });
    },
    [emailExists, formError, reverseAuthProviders, t],
  );

  const isFormValid = !isDirty || !isValid;

  const showConfirmationModal = useCallback(() => {
    setShowConfirmModal(!showConfirmModal);
  }, [showConfirmModal]);

  const showResetPasswordModal = useCallback(() => {
    setShowResetPassword(!showResetPassword);
  }, [showResetPassword]);

  const submitAuthForm = useCallback(async () => {
    const { email, password } = getValues();
    if (isCordova && isSignUp && !countrySelected) {
      dispatch(toggleSelectedCountryError(true, AuthProviders.PASSWORD));
    }

    dispatch(disableAuthForm);

    let result = undefined;

    if (isSignUp) {
      result = await register({
        type: 'password',
        email,
        password,
        countryCode,
        locale,
      });
    } else {
      result = await login({
        type: 'password',
        email,
        password,
        countryCode,
        locale,
      });
    }

    if (result && result.status === 'fail') {
      const error: BridebookError = {
        prop: 'email',
        name: '',
        code: 'custom/api-authenticate-failed',
        message: '',
      };
      dispatch(signInError(error, AuthProviders.PASSWORD));
    }

    if (result && result.status === 'success' && !result.isCollaborator) {
      await redirectAfterLoginOrSignup(result);
    }
    if (result && result.status === 'success' && result.isCollaborator) {
      prepareRedirectAfterLoginOrSignup(result);
      setCollaborationAnimationVisible(true);
    }
  }, [
    countryCode,
    countrySelected,
    dispatch,
    getValues,
    isCordova,
    isSignUp,
    login,
    prepareRedirectAfterLoginOrSignup,
    redirectAfterLoginOrSignup,
    register,
    setCollaborationAnimationVisible,
  ]);

  const handleSignInOrSignUp = useCallback(() => {
    if (isSignUp) {
      !isFormValid && showConfirmationModal();
    } else {
      !isFormValid && submitAuthForm();
    }
  }, [isFormValid, isSignUp, showConfirmationModal, submitAuthForm]);

  const styles = useMemo(() => componentStyles({ isDirty, isValid }), [isDirty, isValid]);

  return (
    <Box>
      <Box style={styles.inputContainer}>
        <form onSubmit={handleSubmit(onSubmit)} style={styles.formContainer}>
          <Controller
            name={'email'}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('invalidEmail'),
              },
            }}
            render={({ field, fieldState }) => (
              <InputV2
                label={t('authEmailAddress')}
                placeholder={t('authPlaceholder')}
                type={'email'}
                {...field}
                error={getError(fieldState.error?.message)}
                autoFocus={isDesktop}
              />
            )}
          />
          <Controller
            name={'password'}
            control={control}
            rules={{
              required: true,
              ...(isSignUp && { minLength: { value: 6, message: t('passwordTooShort') } }),
            }}
            render={({ field, fieldState }) => (
              <InputPassword
                label={t('authPassword')}
                placeholder={t('authPlaceholder')}
                {...field}
                error={
                  (isSignUp && fieldState.error?.message) ||
                  (authForm.error && authForm.error.prop === 'password' && t('wrongPassword'))
                }
              />
            )}
          />
          {!isSignUp && (
            <Box style={styles.resetPasswordContainer}>
              <Box style={styles.resetPassword} onClick={showResetPasswordModal}>
                {t('resetPassword')}
              </Box>
            </Box>
          )}
          <ButtonV2
            size={'large'}
            onClick={handleSignInOrSignUp}
            disabled={authForm.disabled}
            loading={authForm.disabled}
            dataTest="auth-button-confirm">
            {isSignUp ? t('signUp') : t('logIn')}
          </ButtonV2>
          <ConfirmEmailPopup
            showConfirmModal={showConfirmModal}
            authData={authData}
            onClose={showConfirmationModal}
            onConfirm={submitAuthForm}
          />
          <Box style={styles.resetPasswordPopup}>
            <ResetPasswordPopup showModal={showResetPassword} onClose={showResetPasswordModal} />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default memo(SignupLoginForm);
