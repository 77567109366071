import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { useFela } from 'react-fela';
import { Box, Button } from '@bridebook/ui';
import SignupLoginForm from 'components/auth/signup-login-form/signup-login-form';
import SocialAuth from 'components/auth/social-auth/social-auth';
import WarningComponent from 'components/auth/warning-component/warning-component';
import { getIsMobile } from 'lib/app/selectors';
import { getCollaboratorsName, isCollaboratorInvite } from 'lib/auth/selectors';
import { ICredentialsFields } from 'lib/auth/types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import componentStyles from './signup-and-login-wrap.style';

interface IProps {
  authData: ICredentialsFields;
  setAuthData: Dispatch<SetStateAction<ICredentialsFields>>;
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
  toggleSignUp: (url: string) => void;
}

const LogInComponent = ({
  authData,
  setAuthData,
  setCollaborationAnimationVisible,
  toggleSignUp,
}: IProps) => {
  const { css } = useFela();
  const { t } = useTranslation('signup');
  const isCordova = useSelector(getIsCordova);
  const isMobile = useSelector(getIsMobile);
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const collaboratorsName = useSelector(getCollaboratorsName);
  const collaboratorMethod = useSelector((state) => state.auth.collaboratorInvite.method);

  const handleOnClick = () => {
    toggleSignUp(UrlHelper.signup);
  };

  const styles = componentStyles({
    isSignUp: false,
    isCordova,
    isMobileWelcome: false,
    showEmailAndPasswordForm: true,
  });
  return (
    <Box style={styles.contentContainer}>
      {!isCollaboratorsInvite && <Box style={styles.signupTitle}>{t('logIn')}</Box>}
      {isCollaboratorsInvite && (
        <>
          {!collaboratorMethod && (
            <Box>
              <Box style={styles.collaboratorTitle}>{t('letsPlanTogether')}</Box>
              <Box style={styles.collaboratorSubtitle}>
                {t('collaborator.login', {
                  partnerName1: collaboratorsName[0],
                  partnerName2: collaboratorsName[1],
                })}
              </Box>
            </Box>
          )}
          {collaboratorMethod === 'askForFeedback' && (
            <Box>
              <Box style={styles.collaboratorFeedbackTitle}>
                {t('collaborator.feedback.login.title')}
              </Box>
              <Box style={styles.collaboratorFeedbackSubtitle}>
                {t('collaborator.feedback.login.subtitle', {
                  partnerName1: collaboratorsName[0],
                  partnerName2: collaboratorsName[1],
                })}
              </Box>
            </Box>
          )}
        </>
      )}
      <Box px={3}>
        <SignupLoginForm
          setAuthData={setAuthData}
          authData={authData}
          setCollaborationAnimationVisible={setCollaborationAnimationVisible}
        />
      </Box>
      {!isCollaboratorsInvite &&
        (!isMobile ? (
          <Box style={styles.otherMethod}>{t('loginOtherMethod')}</Box>
        ) : (
          <Box style={styles.loginMethodsDividerWrapper}>
            <hr className={css(styles.loginMethodsDividerLineBefore)} />
            {t('loginMethod.or')}
            <hr className={css(styles.loginMethodsDividerLineAfter)} />
          </Box>
        ))}
      <Box style={styles.socialContainer}>
        <Box>
          <SocialAuth setCollaborationAnimationVisible={setCollaborationAnimationVisible} />
        </Box>
        {isCollaboratorsInvite && (
          <Box style={styles.warningContainer}>
            <WarningComponent warningText={t('collaborator.login.warning')} />
          </Box>
        )}
        <Button
          noIcon
          style={styles.changeSingupLogin}
          onClick={handleOnClick}
          theme="ghost"
          text={`${t('dontHaveAnAccount')} ${t('signUp')}`}
        />
      </Box>
    </Box>
  );
};

export default LogInComponent;
