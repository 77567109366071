import { useTranslation } from 'next-i18next';
import React, { ReactNode, useRef, useState } from 'react';
import { Box } from '@bridebook/ui';
import { BackgroundStarsAnimation } from 'components/onboarding-new/components/animations/blocks/background-stars-animation';
import { BridebookReadyAnimation } from 'components/onboarding-new/components/animations/blocks/bridebook-ready-animation';
import {
  fadeInRightAnimation,
  fadeOutAnimation,
} from 'components/onboarding-new/components/animations/helpers/fade-animations';
import {
  AnimationTiming,
  useAnimateSequence,
} from 'components/onboarding-new/components/animations/helpers/use-animate-sequence';
import componentStyle from './collaboration-flow-end-animation.style';

interface IProps {
  partners?: string[];
  onFinish: () => void;
}

const CollaborationFlowEndAnimation = ({ partners, onFinish }: IProps) => {
  const { t } = useTranslation('signup');
  const [bridebookReadyVisible, setBridebookReadyVisible] = useState(false);

  const refNames = useRef<ReactNode>();
  const refSlide = useRef<ReactNode>();

  const onAnimationFinish = () => {
    onFinish();
  };

  const sequence: AnimationTiming[] = [
    {
      target: refSlide,
      delay: 0.5,
      duration: 1,
      style: fadeInRightAnimation(1),
      showOnStart: true,
      onStart: () => window.scroll(0, 50),
    },
    {
      target: refNames,
      delay: 0.5,
      duration: 0.2,
      style: fadeOutAnimation(0.2),
      onFinish: () => setBridebookReadyVisible(true),
    },
  ];

  useAnimateSequence(sequence);

  const styles = componentStyle();

  return (
    <Box style={styles.wrapper} ref={refSlide} data-name="CollaborationFlowEndAnimation">
      <BackgroundStarsAnimation />
      <Box alignItems={'center'}>
        <Box style={styles.headingNames} ref={refNames} id="names">
          <Box style={{ textAlign: 'center' }}>{t('animation.congratulations')}</Box>
          <Box style={styles.subTitle}>{t('animation.your.joined.wedding')}</Box>
          <Box style={styles.names}>{partners && `${partners[0]} & ${partners[1]}`}</Box>
        </Box>
      </Box>
      {bridebookReadyVisible && <BridebookReadyAnimation onAnimationFinish={onAnimationFinish} />}
    </Box>
  );
};

export default CollaborationFlowEndAnimation;
