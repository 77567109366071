import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  topSection: FelaCSS;
  signupContent: FelaCSS;
  contentContainer: FelaCSS;
  sparkles: FelaCSS;
  termsAndConditions: FelaCSS;
  termsAndConditionsLink: FelaCSS;
  mobileWelcomeButton: FelaCSS;
  mobileWelcomeButtonWrapper: FelaCSS;
  mobileSpacer: FelaCSS;
  tabletTopSpacer: FelaCSS;
  tabletBottomSpacer: FelaCSS;
}

const styles: IStyles = {
  topSection: {
    position: 'relative',
    width: '100%',
  },
  signupContent: {
    width: '90vw',
    alignItems: 'center',
    maxWidthXsMax: {
      width: '100vw',
    },
    height: '100%',
    minHeight: '88vh',
    supportsSafeAreaInset: {
      marginBottom: 4,
    },
  },
  contentContainer: {
    width: '100%',
    maxWidthMdMax: {
      maxWidth: 450,
    },
    padding: spacing[6],
    gap: spacing[5],
    flexGrow: 1,
  },
  sparkles: {
    position: 'absolute',
    zIndex: 999,
    width: '100%',
    height: 280,
    pointerEvents: 'none',
  },
  termsAndConditions: {
    fontSize: 12,
    color: colors.space,
    textAlign: 'center',
    display: 'block',
  },
  termsAndConditionsLink: {
    textDecorationLine: 'underline',
    color: colors.space,
  },
  mobileWelcomeButton: {
    paddingVertical: spacing[5],
    paddingHorizontal: spacing[8],
  },
  mobileWelcomeButtonWrapper: {
    marginTop: 'auto',
  },
  mobileSpacer: {
    height: '32px',
  },
  tabletTopSpacer: {
    height: '8px',
  },
  tabletBottomSpacer: {
    height: '4px',
  },
};

export default styles;
