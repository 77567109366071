import React, { memo, useCallback, useRef, useState } from 'react';
import { Input, InputProps } from '../../../components/bbcommon/input/input';
import { Box } from '../../../fela-components';
import { colors } from '../../../fela-themes';
import { IconEyeCross, IconEyeView } from '../../../icons';
import { IColorTypes } from '../../../types';
import componentStyles from './input-password.style';

const InputPasswordComponent = (props: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [iconColor, setIconColor] = useState<keyof IColorTypes>(
    colors.space40 as keyof IColorTypes,
  );

  const [passwordToggle, setPasswordToggle] = useState(false);

  const togglePassword = useCallback(() => {
    const { type } = inputRef.current;
    inputRef.current.type = type === 'password' ? 'text' : 'password';
    setPasswordToggle(!passwordToggle);
  }, [passwordToggle]);

  const styles = componentStyles();

  return (
    <Input
      ref={inputRef}
      {...props}
      type="password"
      afterInput={
        <Box
          style={styles.passwordIconContainer}
          onClick={togglePassword}
          onMouseEnter={() => setIconColor('indigoCrush40')}
          onMouseLeave={() => setIconColor('space40')}>
          {passwordToggle ? (
            <IconEyeCross color={iconColor} width={22} />
          ) : (
            <IconEyeView color={iconColor} width={22} mt={2} />
          )}
        </Box>
      }
    />
  );
};

export const InputPassword = memo(InputPasswordComponent);
