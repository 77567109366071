import { Box } from '@bridebook/ui';
import componentStyles from './warning-component.style';

interface IProps {
  warningText: string;
}

const WarningComponent = ({ warningText }: IProps) => {
  const styles = componentStyles();

  return (
    <Box maxW={350}>
      <Box style={styles.warningContainer}>{warningText}</Box>
    </Box>
  );
};

export default WarningComponent;
