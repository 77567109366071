import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  topSection: FelaCSS;
  changeSingupLogin: FelaCSS;
  signupMethod: FelaCSS;
  signupContent: FelaCSS;
  otherMethod: FelaCSS;
  collaboratorTitle: FelaCSS;
  collaboratorSubtitle: FelaCSS;
  collaboratorFeedbackTitle: FelaCSS;
  collaboratorFeedbackSubtitle: FelaCSS;
  contentContainer: FelaCSS;
  socialContainer: FelaCSS;
  warningContainer: FelaCSS;
  mobileSafeArea: FelaCSS;
  signupTitle: FelaCSS;
  signupButtonsContainer: FelaCSS;
  loginMethodsDividerWrapper: FelaCSS;
  loginMethodsDividerLineBefore: FelaCSS;
  loginMethodsDividerLineAfter: FelaCSS;
  skeleton: FelaCSS;
}

interface IStylesProps {
  isSignUp: boolean;
  isCordova: boolean;
  isMobileWelcome: boolean;
  isCollaboratorsInvite?: boolean;
  showEmailAndPasswordForm?: boolean;
}

const styles = ({
  isSignUp,
  isCordova,
  isMobileWelcome,
  isCollaboratorsInvite,
  showEmailAndPasswordForm,
}: IStylesProps): IStyles => ({
  topSection: {
    position: 'relative',
    width: '100%',
    height: '280px',
    flexGrow: '1',
    minWidthXsMin: {
      maxWidthMdMin: {
        marginBottom: 36,
      },
    },
  },
  signupMethod: {
    fontDefaultSemiBold: 14,
    textAlign: 'start',
    paddingLeft: spacing[6],
  },
  changeSingupLogin: {
    alignSelf: 'center',
    width: 'fit-content',
    marginTop: 6,
    color: colors.indigoCrush,
    fontDefault: 16,
    marginBottom: 4,
  },
  mobileSafeArea: {
    position: 'fixed',
    width: '100%',
    paddingTop: 'env(safe-area-inset-top)',
    backgroundColor: colors.transparent,
    zIndex: 1,
  },
  signupContent: {
    width: '100%',
    maxWidthMdMax: {
      maxWidth: 580,
      alignItems: 'center',
    },
    height: '100%',
    ...(isCordova && {
      minHeight: '90vh',
    }),
    supportsSafeAreaInset: {
      marginBottom: 4,
    },
    paddingTop: `calc(${isMobileWelcome ? '0' : '50'}px + env(safe-area-inset-top))`,
    desktopDesign: {
      marginTop: spacing[12],
      ...(!isCollaboratorsInvite && { height: 635 }),
      maxWidth: 400,
      paddingTop: 0,
    },
  },
  otherMethod: {
    alignSelf: 'center',
    width: 'fit-content',
    color: isSignUp ? colors.indigoCrush : colors.space,
    fontDefault: 16,
    marginTop: 2,
    marginBottom: 6,
  },
  contentContainer: {
    width: '100%',
    maxWidthMdMax: {
      maxWidth: 450,
    },
  },
  socialContainer: {
    height: '100%',
    justifyContent: 'space-between',
  },
  collaboratorTitle: {
    textAlign: 'start',
    fontPlayfair: 38,
    marginTop: 6,
    paddingHorizontal: spacing[6],
  },
  collaboratorSubtitle: {
    textAlign: 'start',
    fontDefault: 16,
    marginTop: spacing[6],
    marginBottom: showEmailAndPasswordForm ? 0 : spacing[6],
    paddingHorizontal: spacing[6],
  },
  collaboratorFeedbackTitle: {
    textAlign: 'start',
    fontDefault: 16,
    marginTop: 12,
    paddingHorizontal: spacing[6],
  },
  collaboratorFeedbackSubtitle: {
    textAlign: 'start',
    fontPlayfair: 46,
    marginTop: spacing[1],
    marginBottom: showEmailAndPasswordForm ? 0 : spacing[6],
    paddingLeft: spacing[6],
  },
  warningContainer: {
    marginBottom: spacing[8],
    marginTop: isSignUp && showEmailAndPasswordForm ? 0 : spacing[8],
    width: '100%',
    alignItems: 'center',
    textAlign: 'start',
  },
  signupTitle: {
    textAlign: 'start',
    paddingTop: 6,
    paddingBottom: 0,
    paddingLeft: 6,
    fontPlayfair: 40,
    lineHeight: '120%',
    alignSelf: 'flex-start',
  },
  signupButtonsContainer: {
    gap: spacing[4],
  },
  loginMethodsDividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    alignItems: 'center',
    marginHorizontal: spacing[5],
    marginBottom: spacing[5],
    paddingHorizontal: spacing[2],
    color: colors.space75,
  },
  loginMethodsDividerLineBefore: {
    content: '',
    flexGrow: 1,
    background: colors.space40,
    height: '1px',
    fontSize: '0px',
    lineHeight: '0px',
    marginRight: 5,
  },
  loginMethodsDividerLineAfter: {
    content: '',
    flexGrow: 1,
    background: colors.space40,
    height: '1px',
    fontSize: '0px',
    lineHeight: '0px',
    marginLeft: 5,
  },
  skeleton: {
    height: 150,
  },
});

export default styles;
