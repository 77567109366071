import { CSSProperties } from 'react';
import { colors } from '../../../themes/variables';
import { FelaCSS, FelaCSSWithCustomSelectors } from '../../fela/flowtypes';

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
  error: FelaCSS;
  helper: FelaCSS;
  list: FelaCSS;
  listInner: FelaCSS;
  label: FelaCSS;
  listItem: (selected: boolean) => FelaCSSWithCustomSelectors;
  innerWrapper: FelaCSS;
  chevron: FelaCSS;
  placeholder: FelaCSS;
  container: FelaCSS;
  filterInputContainer: FelaCSS;
  filterInputWrap: FelaCSS;
  filterInput: CSSProperties;
}

interface IStylesArgs {
  hasError: boolean;
  listOpen: boolean;
  hasValue: boolean;
  isMobile: boolean;
  fullWidth: boolean;
  disabled: boolean;
  optionsFound: boolean;
  direction: 'top' | 'bottom';
}

type IStylesFunction = (args: IStylesArgs) => IStyles;
const styles: IStylesFunction = ({
  hasError,
  listOpen,
  hasValue,
  isMobile,
  fullWidth,
  disabled,
  optionsFound,
  direction,
}) => {
  const isBottomDirection = direction === 'bottom';
  return {
    wrapper: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      height: 58,
    },

    innerWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: 'inherit',
      paddingHorizontal: 4,
      paddingVertical: 2,
      position: 'relative',
      backgroundColor: disabled ? colors.space04 : colors.white,
      border: hasError ? `1px solid ${colors.alerts}` : `1px solid ${colors.space40}`,
      borderRadius: 16,
      ...(!disabled && {
        ':hover': { border: !hasError && !listOpen && `1px solid ${colors.space}` },
      }),

      ...(listOpen &&
        optionsFound && {
          borderColor: colors.indigoCrush,
          ...(!isMobile &&
            (isBottomDirection
              ? {
                  borderBottomColor: 'transparent',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }
              : {
                  borderTopColor: 'transparent',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                })),
        }),

      ...(disabled && {
        pointerEvents: 'none',
        backgroundColor: colors.space04,
        border: `1px solid ${colors.space40}`,
        ':hover': { border: `1px solid ${colors.space40}` },
      }),

      transition: 'border-radius 0.15s ease-in-out',
    },

    placeholder: {
      color: hasValue ? colors.space : colors.space40,
      fontDefault: 16,
      flexDirection: 'row',
      alignItems: 'center',
      gap: '10px',
    },

    container: {
      position: 'relative',
      width: '100%',
      height: 'inherit',
      display: 'flex',
      flexDirection: isBottomDirection ? 'column' : 'column-reverse',
    },

    error: {
      paddingTop: 1,
      paddingHorizontal: 4,
      fontDefaultSemiBold: 12,
      color: colors.alerts,
      maxWidth: '100%',
      margin: 0,
    },

    helper: {
      paddingTop: 4,
      fontDefaultSemiBold: 12,
      color: colors.space60,
      maxWidth: 295,
      paddingLeft: 16,
      paddingRight: 16,
    },

    list: {
      minWidth: '100px',
      width: fullWidth ? undefined : '352px',
      position: 'absolute',
      ...(isBottomDirection
        ? {
            top: 0,
          }
        : {
            bottom: 0,
          }),
      left: 0,
      right: fullWidth ? 0 : undefined,
      backgroundColor: 'white',
      zIndex: 100,
      borderRadius: 16,
      fontDefault: 16,
      paddingLeft: 0,
      listStyleType: 'none',
      overflow: 'hidden',
      display: listOpen ? 'flex' : 'none',

      animationName: {
        '0%': {
          opacity: 0,
          transform: `translateY(${isBottomDirection ? -20 : 20}px)`,
        },
        '100%': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      } as unknown as FelaCSS['animationName'],
      animationDuration: '0.15s',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationTimingFunction: 'ease-in-out',

      ...(listOpen &&
        optionsFound && {
          maxHeight: '240px',
          border: `1px solid ${colors.indigoCrush}`,
          ...(!isMobile &&
            (isBottomDirection
              ? {
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                }
              : {
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                })),
        }),
    },

    listInner: {
      height: '100%',
      overflow: 'auto',
    },

    label: {
      fontDefaultSemiBold: 12,
      color: disabled ? colors.space75 : colors.space,
    },

    listItem: (selected) => ({
      flexDirection: 'row',
      alignItems: 'center',
      gap: '10px',
      fontDefault: 16,
      color: colors.space,
      cursor: 'pointer',
      padding: 4,
      paddingHorizontal: 4,

      ...(selected
        ? {
            backgroundColor: colors.indigoCrush10,
            fontDefaultSemiBold: 16,
            color: colors.space,
          }
        : {
            hover: {
              backgroundColor: colors.indigoCrush04,
            },
          }),
    }),

    chevron: {
      marginLeft: 2,
      ...(listOpen && !isMobile && { transform: 'scaleY(-1)' }),
      transition: 'transform 0.25s ease-in-out',
    },

    filterInputContainer: {
      width: '100%',
    },

    filterInputWrap: {
      width: '100%',
      fontDefault: 16,
    },

    filterInput: {
      width: '100%',
      height: '100%',
      outline: 'none',
      border: '1px solid transparent',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  };
};

export default styles;
