import React, { useCallback, useMemo, useState } from 'react';
import { ICountrySelectorProps } from '../country-selector/country-selector';
import { Dropdown } from '../dropdown/dropdown';

const FlagCountrySelector = ({
  onSelect,
  options,
  value,
  selected,
  showFilter,
  isMobile,
  children,
  error,
  required,
  renderOption,
  ...rest
}: ICountrySelectorProps) => {
  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => setShowModal(false), []);
  const onInputClick = useCallback(() => setShowModal(true), []);

  const sanitizedOptions = useMemo(() => options.filter(Boolean), [options]);

  return (
    <>
      <Dropdown
        showFilter={showFilter}
        options={sanitizedOptions}
        onSelect={onSelect}
        isMobile={isMobile}
        onInputClick={onInputClick}
        renderOption={(option) => (
          <>{option.flagImg && <img src={option.flagImg} width={12} loading="lazy" />}</>
        )}
        value={(option) => <>{option.flagImg && <img src={option.flagImg} width={25} />}</>}
        selected={selected}
        error={error}
        required={required}
        wrapperStyle={{ height: 34 }}
        innerWrapperStyle={{ paddingHorizontal: 3 }}
        {...rest}
      />
      {children?.(onClose, onSelect, showModal)}
    </>
  );
};

export default FlagCountrySelector;
