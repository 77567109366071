import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, ButtonV2 } from '@bridebook/ui';
import Modal from 'components/bbcommon/modal';
import { ICredentialsFields } from 'lib/auth/types';
import componentStyles from './confirm-email-popup.style';

interface IProps {
  showConfirmModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
  authData: ICredentialsFields;
}

const ConfirmEmailPopup = ({ showConfirmModal, onClose, authData, onConfirm }: IProps) => {
  const { t } = useTranslation('signup');

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const styles = componentStyles();

  return (
    <Modal show={showConfirmModal} id={'signup-login-form'} maxWidth={550} onClose={onClose}>
      <Box style={styles.modalContainer}>
        <Box style={styles.text}>{t('emailCorrect')}</Box>
        <Box style={styles.userEmail}>{authData.email}</Box>
        <Box style={styles.confirmContainer}>
          <ButtonV2 autoFocus size={'large'} onClick={handleConfirm} dataTest="confirm-email">
            {t('confirmEmail')}
          </ButtonV2>
        </Box>
        <ButtonV2 onClick={onClose} variant={'ghost'}>
          {t('backFromConfirm')}
        </ButtonV2>
      </Box>
    </Modal>
  );
};

export default ConfirmEmailPopup;
