import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  signupContainer: FelaCSS;
  container: FelaCSS;
  imageContainer: FelaCSS;
  image: FelaCSS;
  imageGallery: FelaCSS;
  logoContainer: FelaCSS;
  tabletHeader: FelaCSS;
  countrySelectWrapper: FelaCSS;
}

const styles = (): IStyles => ({
  container: {
    flexDirection: 'row',
  },
  signupContainer: {
    maxWidthMdMax: {
      alignItems: 'center',
    },
    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
    },
    desktopDesign: {
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
  },
  imageContainer: {
    width: '50%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '62.5%',
    height: '100%',
    paddingBottom: 24,
  },
  imageGallery: {
    transform: 'translate(0, -35%)',
  },
  logoContainer: {
    position: 'absolute',
    top: 25,
    left: 50,
    supportsSafeAreaInset: {
      top: 'calc(25px + env(safe-area-inset-top))',
    },
  },
  tabletHeader: {
    display: 'none',
    alignItems: 'flex-start',
    width: '100%',
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderBottom: `1px solid ${colors.space15}`,
    minWidthXsMin: {
      maxWidthMdMin: {
        display: 'flex',
      },
    },
  },
  countrySelectWrapper: {
    alignSelf: 'flex-end',
    paddingTop: 4,
    paddingHorizontal: 4,
  },
});

export default styles;
