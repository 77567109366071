import React, { useRef } from 'react';
import { Box } from '@bridebook/ui';
import { useLottieAnimation } from '@bridebook/ui/src/utils/hooks';
import { onboardingImages } from 'lib/onboarding-new/helpers';

interface IProps {
  size?: number;
  loop?: boolean;
}

export const ConfettiSmallAnimation = ({ size = 120, loop = false }: IProps) => {
  const animationContainer = useRef<HTMLDivElement>();
  useLottieAnimation({
    animationContainer,
    animationJSON: ConfettiSmallAnimation.animationJSON,
    loop,
  });

  return (
    <Box
      w={size}
      h={size}
      setRef={animationContainer}
      style={{
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: 'translate3d(-50%, -50%, 0)',
        overflow: 'hidden',
        zIndex: 99,
      }}
    />
  );
};

ConfettiSmallAnimation.animationJSON = `${onboardingImages}/lottie/confetti-small.json`;
