import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { Box, ICountryOption, InputV2 } from '@bridebook/ui';
import { IconSearch } from '@bridebook/ui/src/icons';
import componentStyles from 'app-shared/components/contry-selector/country-select-modal-content.style';
import { MobileSlideContentWrapper } from '../mobile-slide-content/mobile-slide-content-wrapper';

interface IProps {
  options: ICountryOption[];
  onClick: (value: ICountryOption) => void;
  onClose(): void;
  modalHeader?: string;
  showFilter?: boolean;
  filterPlaceholder?: string;
  selected?: string | number;
  /**
   * Render option function. If provided, it will be called for each option in the list and the result will
   * be displayed as the option in the list.
   */
  renderOption?: (option: ICountryOption) => ReactNode;
}

export const CountrySelectModalContent = ({
  options,
  onClick,
  onClose,
  modalHeader,
  showFilter,
  filterPlaceholder,
  selected,
  renderOption,
}: IProps) => {
  const [displayOptions, setDisplayOptions] = useState(options);

  const onCountryClick = (value: ICountryOption) => {
    onClick(value);
    onClose();
    setDisplayOptions(options);
  };

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '') {
        setDisplayOptions(options);
        return;
      }
      setDisplayOptions(
        options.filter((option) => option.label?.toLowerCase().includes(value.toLowerCase())),
      );
    },
    [options],
  );

  const styles = componentStyles();

  return (
    <MobileSlideContentWrapper onClose={onClose} title={modalHeader}>
      {showFilter && (
        <Box style={styles.searchWrap}>
          <InputV2
            type="text"
            placeholder={filterPlaceholder}
            afterInput={<IconSearch width={18} color="indigoCrush" style={styles.icon} />}
            onChange={handleFilterChange}
            autoFocus
          />
        </Box>
      )}
      {displayOptions?.map((option, index) => (
        <Box
          key={index}
          style={styles.listItem(option.value === selected)}
          onClick={() => onCountryClick(option)}>
          {option.flagImg && <img src={option.flagImg} style={styles.flagImg} loading="lazy" />}
          {renderOption?.(option) || option.label}
        </Box>
      ))}
    </MobileSlideContentWrapper>
  );
};
