import { useRouter } from 'next/router';
import { useState } from 'react';
import { Box, ImageX } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import { Link } from 'app-shared/components/link/link';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import CollaborationFlowEndAnimation from 'components/auth/animations/collaboration-flow-end-animation';
import { useAuthContext } from 'components/auth/auth-context';
import AuthCountrySelect from 'components/auth/country-select/auth-country-select';
import componentStyles from 'components/auth/signup-component/signup-component.style';
import SignupAndLoginWrap from 'components/auth/signup-wrap/signup-and-login-wrap';
import { getIsDesktop, getIsMobile } from 'lib/app/selectors';
import { isCollaboratorInvite } from 'lib/auth/selectors';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { UrlHelper } from 'lib/url-helper';
import { imgixBaseURL, useSelector } from 'lib/utils';

const SignupComponent = () => {
  const router = useRouter();
  const { triggerRedirectAfterLoginOrSignup } = useAuthContext();
  const market = useMarket();
  const imgPostfix = market.language;
  const isCordova = useSelector(getIsCordova);
  const isDesktop = useSelector(getIsDesktop);
  const isCollaborator = useSelector(isCollaboratorInvite);
  const collaboratorsName = useSelector((state) => state.auth.collaboratorInvite.partners);
  const [collaborationAnimationVisible, setCollaborationAnimationVisible] = useState(false);
  const [authData, setAuthData] = useState({ email: '', password: '' });
  const isMobile = useSelector(getIsMobile);
  const isWelcomePageOrLoginPage = router.query.welcome === 'show' || router.query.mode === 'login';
  const onAnimationFinish = async () => {
    await triggerRedirectAfterLoginOrSignup();
  };
  const showCountrySelector = !isCordova && isMobile && !isWelcomePageOrLoginPage;

  const styles = componentStyles();
  return (
    <>
      {isDesktop ? (
        <Box style={styles.container}>
          <Box style={styles.signupContainer}>
            <Box style={styles.logoContainer}>
              <Link href={UrlHelper.index} legacyBehavior>
                <Box as="a">
                  <BridebookLogo color="indigoCrush" width={129} />
                </Box>
              </Link>
            </Box>
            <SignupAndLoginWrap
              authData={authData}
              setAuthData={setAuthData}
              setCollaborationAnimationVisible={setCollaborationAnimationVisible}
            />
          </Box>
          <Box style={styles.imageContainer}>
            <ImageX
              w={500}
              h={0}
              style={styles.image}
              imageStyle={styles.imageGallery}
              src={`${imgixBaseURL}/assets/signup-imgs/background-desktop-qr-${imgPostfix}.jpg`}
            />
          </Box>
        </Box>
      ) : (
        <Box style={styles.signupContainer}>
          {showCountrySelector && (
            <Box style={styles.countrySelectWrapper}>
              <AuthCountrySelect />
            </Box>
          )}
          <Box style={styles.tabletHeader}>
            <Link href={UrlHelper.index} legacyBehavior>
              <Box as="a">
                <BridebookLogo color="indigoCrush" width={129} />
              </Box>
            </Link>
          </Box>
          <SignupAndLoginWrap
            authData={authData}
            setAuthData={setAuthData}
            setCollaborationAnimationVisible={setCollaborationAnimationVisible}
          />
        </Box>
      )}
      {collaborationAnimationVisible && isCollaborator && (
        <CollaborationFlowEndAnimation partners={collaboratorsName} onFinish={onAnimationFinish} />
      )}
    </>
  );
};

export default SignupComponent;
