import { Trans } from 'next-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { Box } from '@bridebook/ui';
import { ConfettiSmallAnimation } from 'components/onboarding-new/components/animations/blocks/confetti-small-animation';
import { createAnimation } from 'components/onboarding-new/components/animations/helpers/create-animation';
import {
  AnimationTiming,
  useAnimateSequence,
} from 'components/onboarding-new/components/animations/helpers/use-animate-sequence';
import componentStyles from './bridebook-ready-animation.style';

const headerScaleUpAnimation = createAnimation(
  {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0,
    },
    '70%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(10)',
      opacity: 0,
    },
  },
  {
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'both',
  },
);

const wrapperSlideInAnimation = createAnimation(
  {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  {
    animationTimingFunction: 'ease-in-out',
  },
);

interface IProps {
  onAnimationFinish: () => void;
}

export const BridebookReadyAnimation = ({ onAnimationFinish }: IProps) => {
  const refWrapper = useRef<HTMLDivElement>();
  const refHeader = useRef<HTMLDivElement>();

  const [confettiVisible, setConfettiVisible] = useState(false);

  const toggleConfetti = useCallback(() => {
    setConfettiVisible((prev) => !prev);
  }, []);

  const sequence: AnimationTiming[] = [
    {
      target: refWrapper,
      delay: 0,
      duration: 1,
      style: wrapperSlideInAnimation(1),
    },
    {
      target: refHeader,
      delay: 0,
      duration: 2.5,
      style: headerScaleUpAnimation(4),
      showOnStart: true,
      onStart: toggleConfetti,
      onFinish: onAnimationFinish,
    },
  ];

  useAnimateSequence(sequence);

  const styles = componentStyles();

  return (
    <Box style={styles.wrapper} setRef={refWrapper} data-name="BridebookReadyAnimation">
      {confettiVisible && <ConfettiSmallAnimation size={2000} />}
      <Box style={styles.header} setRef={refHeader}>
        <Trans ns="onboardingNew" i18nKey="animation.yourBridebookIsReady">
          Your Bridebook
          <br />
          is ready!
        </Trans>
      </Box>
    </Box>
  );
};

BridebookReadyAnimation.assets = [ConfettiSmallAnimation.animationJSON];
