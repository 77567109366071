import type { LottiePlayer } from 'lottie-web';
import { MutableRefObject, useEffect, useState } from 'react';

/**
 * Hook for showing Lottie animation.
 * Provide a container via useRef/setRef.
 * Container should have some styling for presenting the animation.
 */
export const useLottieAnimation = ({
  animationContainer,
  animationJSON,
  loop = true,
}: {
  animationContainer: MutableRefObject<HTMLDivElement | undefined>;
  animationJSON: string;
  loop?: boolean;
}) => {
  const [playerLoaded, setPlayerLoaded] = useState<LottiePlayer>();

  useEffect(() => {
    const { current: wrapperEl } = animationContainer;

    if (!wrapperEl) return;

    (async () => {
      if (playerLoaded) return;
      const lottie = (await import('lottie-web')) as unknown as LottiePlayer;

      lottie.loadAnimation({
        autoplay: true,
        container: wrapperEl,
        loop,
        path: animationJSON,
        renderer: 'svg',
      });

      setPlayerLoaded(lottie);
    })();

    return () => {
      playerLoaded?.destroy();
    };
  }, [playerLoaded, animationContainer, animationJSON, loop]);
};
